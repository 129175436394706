import React, { useMemo } from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

import SEO from 'components/SEO'
import VideoCard from 'components/VideoCard'
import Select from 'components/Select'
import PartnersLinks from 'components/PartnersLinks'
import Banner from 'components/Banner'
import styles from './VideoList.module.scss'

const PopularVideosPage = ({ data }) => {
  const { videos, site: { siteMetadata }, banner } = data

  const breadcrumb = useMemo(() => ({
    '@context': 'http://schema.org',
    '@type': 'BreadcrumbList',
    'itemListElement': [
      {
        "@type": "ListItem",
        "position": 1,
        "item": {
          "@id": `${siteMetadata.siteUrl}/videos/`,
          "name": "Videos"
        }
      },
      {
        "@type": "ListItem",
        "position": 2,
        "item": {
          "@id": `${siteMetadata.siteUrl}/videos/popular/`,
          "name": "Most popular"
        }
      },
    ]
  }), [siteMetadata.siteUrl])

  const sloppyPartners = [
    {name: 'Porn Wideo', url: 'http://pornwideo.com/videos/amateur/'},
  ]
  
  return (
    <>
    <SEO
      breadcrumb={breadcrumb}
      title={`Best cam recordings: Ultra HD cam show videos uploaded daily | SloppyDeep`}
      description={`SloppyDeep brings you the top webcam girl videos in full HD. Just relax, get comfortable and choose between teen, asian, chubby, milf webcam videos and many more! You will find only the top recorded cam shows here on sloppydeep.com, the best cam tube`}
      keywords={`sloppydeep videos, best cam tube, cam recording free, free cam videos`}
      imagePath={banner.publicURL}
      imageAlt={'redhead teen cam girl wearing pink lingerie and posing sexy'}
      pagePath={'/videos/popular/'}
      robots='index, follow'
    />
    <Helmet>
      { /* prefetch for thumbnails */}
      <link rel='preconnect dns-prefetch' href='https://galleryn0.awemwh.com' crossorigin />
    </Helmet>
    <main className={`${styles.videoListScreen} screen-container`}>
      <div className={styles.titleBlock}>
        <div className={styles.sectionTitle}>Videos</div>
        <div className={styles.sectionFilter}>
          <Select selected={1} options={[{ text: 'Latest', to: '/videos/' }, { text: 'Popular', to: '/videos/popular/' }]} />
        </div>
      </div>
      <div className={`${styles.videosBlock} video-cards-container`}>
        <VideoList videos={videos.nodes} />
      </div>

      {sloppyPartners.length > 0 &&
        <>
          <div className={styles.titleBlock}>Sloppy Partners</div>
          <div className={styles.partnersLinksBlock}>
            <PartnersLinks links={sloppyPartners} />
          </div>
        </>
      }

    </main>
    </>
  )
}

const VideoList = ({ videos }) => {

  const firstBannerIndex = 3 + Math.floor(Math.random() * 4) // [3-6]
  const bannerFrequency = 10

  let componentList = []

  for (let i = 0; i < videos.length; i++) {
    const shouldPushBanner = (firstBannerIndex + i) % bannerFrequency === 0

    if (shouldPushBanner) {
      componentList.push(i < 10
        ? <Banner.Livejasmin key={`banner-${i}`} />
        : <Banner.Stripcash key={`banner-${i}`} />)
    }

    componentList.push(<VideoCard key={videos[i].slug} data={videos[i]} />)
  }

  return componentList
}

export const query = graphql`
  query PopularVideosPageQuery {

    banner: file(
      relativeDirectory: {regex: "/seo-banner/"},
      name: {eq: "home"}
    ) {
      publicURL
    }

    videos: allSloppyDeepVideo(
        sort: {fields: views, order: DESC}
      ) {
      nodes {
        slug
        title
        views
        publishDate
        duration
        isHd
        thumbnail {
          childImageSharp {
            fluid(maxWidth: 380, maxHeight: 214, quality: 80, cropFocus: CENTER) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        thumbnailAlt
        preview
        model {
          slug
          primaryAka {
            nickname
          }
          profilePicture {
            childImageSharp {
              fluid(maxWidth: 50, maxHeight: 50, quality: 80, cropFocus: CENTER) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }

    site {
      siteMetadata {
        siteUrl
      }
    }
  
  }
`

export default PopularVideosPage
